import React from 'react';
import PlainButtonOrLink, {
  Props,
} from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

/** We need to add some more props to ButtonOrLink[disabled] to prevent keyboarding */
const DisablableButtonOrLink: React.FC<Props> = ({
  href,
  onClick,
  ...props
}) => (
  <PlainButtonOrLink
    {...props}
    {...(props.disabled
      ? { isFakeButton: true, tabIndex: -1 }
      : { href, onClick })}
  />
);

DisablableButtonOrLink.displayName = 'DisablableButtonOrLink';
export default DisablableButtonOrLink;
