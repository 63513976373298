import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForumBreadcrumb } from '@wix/da-papi-types';
import { urlTo } from '../../helpers/url';
import Breadcrumbs from '../../_partials/Breadcrumbs';

import s from './SearchBreadcrumbs.scss';

export interface Props {
  estTotal?: number;
  searchTerm?: string;
  breadcrumbs?: PapiForumBreadcrumb[];
  className?: string;
}

/** breadcrumbs specific to the search page that allow widening a search
 *  from a specific forum to forum-wide and displaying result count */
const SearchBreadcrumbs: React.FC<Props> = ({
  className,
  estTotal,
  searchTerm,
  breadcrumbs = [],
}) => {
  const { t } = useTranslation();

  const convertCrumbToSearchLink = useCallback(
    (originalLink: string): string => {
      const url = new URL(originalLink);
      if (searchTerm) {
        // strip the root forum name to get a proper forumpath
        const forumpath = encodeURIComponent(
          url.pathname.replace(/^\/forum(\/|$)/, '')
        );
        url.pathname = 'forum/search';
        url.searchParams.set('q', searchTerm);
        forumpath && url.searchParams.set('forumpath', forumpath);
      }
      const link = url.toString();
      return urlTo(link);
    },
    [searchTerm]
  );

  if (estTotal === undefined || !searchTerm) {
    return null;
  }

  const hasBreadcrumbs = !!breadcrumbs.length;
  const localeKey = hasBreadcrumbs
    ? 'ForumSearchPage.SearchBreadcrumbs'
    : 'ForumSearchPage.SearchBreadcrumbs.rootForum';
  return (
    <div className={cn(className, s['root'])}>
      {t(localeKey, {
        postProcess: 'reactPostprocessor',
        results: t('ForumSearchPage.SearchBreadcrumbs.results', {
          count: estTotal,
        }),
        searchTerm: <b className={s['search-term']}>{searchTerm}</b>,
        breadcrumbs: (
          <Breadcrumbs
            className={s['breadcrumbs']}
            breadcrumbs={breadcrumbs}
            transformLinkFn={convertCrumbToSearchLink}
          />
        ),
      })}
    </div>
  );
};
SearchBreadcrumbs.displayName = 'SearchBreadcrumbs';

export default SearchBreadcrumbs;
