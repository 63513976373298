import { createAction } from 'redux-actions';
import { CommentHistory } from '../types/comments';

export interface FetchCommentHistoryPayload {
  itemId: number;
  commentId: number;
}

export interface FetchCommentHistorySuccessPayload {
  history: CommentHistory;
}

export const fetchCommentHistory = createAction(
  'COMMENT_HISTORY.FETCH.REQUEST',
  (payload: FetchCommentHistoryPayload) => payload
);

export const fetchCommentHistorySuccess = createAction(
  'COMMENT_HISTORY.FETCH.SUCCESS',
  (payload: FetchCommentHistorySuccessPayload) => payload
);
