import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  PapiRequestDaCommentsForumTopicInit,
  PapiDaCommentsForumTopicInit,
} from '@wix/da-papi-types';
import { Main } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { useCurrentUser } from '@wix/da-shared-react/pkg/utils/hooks/useCurrentUser';
import Comment from '@wix/da-shared-react/pkg/Comment/presets/Comment';
import { closeForumThread } from '../../../actions/forums';
import Breadcrumbs from '../_partials/Breadcrumbs/Breadcrumbs';
import ThreadNotice from '../_partials/ThreadNotice';
import ForumThread from './ForumThread';

import s from './ThreadPage.scss';

export interface Props extends Omit<PapiDaCommentsForumTopicInit, 'session'> {
  params?: PapiRequestDaCommentsForumTopicInit;
}

const ThreadPage: React.FC<Props> = ({
  breadcrumbs,
  topicTitle,
  topicComment,
  topicThread,
  topicClosed,
  params,
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const currentUserIsAdmin = currentUser.type === 'admin';
  const canPost = topicThread?.canPostComment;

  const dispatch = useDispatch();
  const extraActions = useMemo(() => {
    const threadId = topicComment?.itemId;
    const closeAction = {
      label: t('ThreadPage.commentActions.closeThread'),
      biData: { menu_item_name: 'close_thread' },
      onClick: () =>
        threadId &&
        confirm(t('ThreadPage.commentActions.closeThread.confirm')) &&
        dispatch(closeForumThread(threadId)),
    };

    const closable = topicComment?.isAuthor && !topicClosed;
    return closable ? [closeAction] : undefined;
  }, [topicComment, topicClosed, t, dispatch]);

  return (
    <div className={classnames(s['root'])}>
      <header className={s['header']}>
        <Breadcrumbs className={s['breadcrumbs']} breadcrumbs={breadcrumbs} />
      </header>

      <Main className={s['main']}>
        <Heading className={s['thread-title']}>{topicTitle}</Heading>

        <hr className={s['divider']} />

        {topicComment && (
          <Comment
            className={s['original-post']}
            comment={topicComment}
            canPostComment={false}
            extraActions={extraActions}
          />
        )}

        {!!topicClosed && (
          <ThreadNotice
            className={s['thread-notice']}
            noticeType={topicClosed}
            isAdmin={currentUserIsAdmin}
          />
        )}

        {topicThread && (
          <ForumThread
            commentableTypeId={topicThread.commentableTypeid}
            commentableItemId={topicThread.commentableItemid}
            parentComment={topicComment}
            allowSelfInit={false}
            postbox={canPost ? 'top' : 'none'}
            statsAndControls="on"
            isInfinite
          />
        )}
      </Main>
    </div>
  );
};

ThreadPage.displayName = 'ThreadPage';
export default ThreadPage;
