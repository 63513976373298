// DS: TA 3.1b Forum Entry (for lists of threads...)
import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForum } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { ROOT_PATH, EMOTICONS_URL } from '../../constants';
import { urlTo } from '../../helpers/url';
import { urlFromBreadcrumbs } from '../../helpers/breadcrumbs';
import DisablableUsername from '../DisablableUsername';
import DisablableButtonOrLink from '../DisablableButtonOrLink';
import UserAndTimestamp from './UserAndTimestamp';

import s from './ThreadEntry.scss';

export interface Props {
  variant?: 'default' | 'search';
  thread: PapiForum;
  isDisabled?: boolean;
  className?: string;
}

const ThreadEntry: React.FC<Props> = ({
  variant = 'default',
  thread,
  isDisabled,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const {
    forumTitle,
    forumPath,
    threadId,
    icon: iconPath = null,
    subject,
    breadcrumbs,
    replyCount = 0,
    author,
    timestamp,
    latestReplyAuthor,
    latestReplyTimestamp,
  } = thread as any;
  const threadUrl = urlTo(`${ROOT_PATH}/${forumPath}/${threadId}`);
  const forumUrl = urlFromBreadcrumbs(breadcrumbs);

  const icon = (
    <img className={s['icon']} alt="_" src={`${EMOTICONS_URL}/${iconPath}`} />
  );
  const authorAndTimestamp = (
    <div className={s['author-and-timestamp']}>
      <DisablableUsername
        className={s['userlink']}
        user={author}
        isDisabled={isDisabled}
        withTooltip
      />
      <Timestamp
        className={s['timestamp']}
        timestamp={timestamp}
        showFullLengthOnMobile
        showTooltip={!isMobile}
      />
    </div>
  );

  const rootClasses = cn(
    className,
    isDisabled && s['root-disabled'],
    s['root']
  );

  if (isMobile) {
    return (
      <DisablableButtonOrLink
        className={rootClasses}
        disabled={isDisabled}
        href={threadUrl}
      >
        {icon}

        <div className={s['mobile-subject-and-author']}>
          <span className={s['subject']}>{subject}</span>
          {authorAndTimestamp}
        </div>

        <span className={s['reply-count']}>
          <span aria-hidden={true}>{replyCount.toLocaleString()}</span>
          <VisuallyHidden>
            {t('ThreadEntry.repliesLabelAndCount', {
              countReplies: replyCount.toLocaleString(),
            })}
          </VisuallyHidden>
        </span>
      </DisablableButtonOrLink>
    );
  }

  const showLatestReply = variant === 'default' && latestReplyAuthor;

  // desktop
  return (
    <div className={rootClasses}>
      <DisablableButtonOrLink
        className={s['subject']}
        disabled={isDisabled}
        href={threadUrl}
      >
        {icon}
        {subject}
      </DisablableButtonOrLink>

      {variant === 'search' && (
        <DisablableButtonOrLink
          className={s['forum-link']}
          disabled={isDisabled}
          href={forumUrl}
        >
          {forumTitle}
        </DisablableButtonOrLink>
      )}

      {authorAndTimestamp}

      <DisablableButtonOrLink
        className={s['reply-count']}
        disabled={isDisabled}
        href={threadUrl}
      >
        <span aria-hidden={true}>{replyCount.toLocaleString()}</span>
        <VisuallyHidden>
          {t('ThreadEntry.repliesLabelAndCount', {
            countReplies: replyCount.toLocaleString(),
          })}
        </VisuallyHidden>
      </DisablableButtonOrLink>

      {showLatestReply ? (
        <UserAndTimestamp
          className={s['latest-reply']}
          author={latestReplyAuthor}
          timestamp={latestReplyTimestamp}
          isDisabled={isDisabled}
        />
      ) : (
        // todo: rethink, test on forum and search
        <span />
      )}
    </div>
  );
};
ThreadEntry.displayName = 'ThreadEntry';

export default ThreadEntry;
