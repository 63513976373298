import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForum } from '@wix/da-papi-types';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import PopularThread from '../PopularThread';

import s from './PopularThreads.scss';

export interface Props {
  popularThreads?: PapiForum[];
  className?: string;
}

/** Another thread list with a header but using the PopularThread component */
const PopularThreads: React.FC<Props> = ({
  popularThreads = [],
  className,
}) => {
  const { t } = useTranslation();

  if (!popularThreads.length) {
    return null;
  }
  return (
    <Section className={cn(className, s['root'])}>
      <Heading className={s['heading']}>{t('PopularThreads.heading')}</Heading>
      <ol>
        {popularThreads.map((thread, i) => {
          return (
            <li key={thread.threadId} className={s['thread-link']}>
              <PopularThread thread={thread} hideForumLink />
            </li>
          );
        })}
      </ol>
    </Section>
  );
};
PopularThreads.displayName = 'PopularThreads';

export default PopularThreads;
