import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import ddt from '@wix/da-ddt';
import {
  requestPuppy,
  requestNapi,
  XhrRequestOptions,
} from '@wix/da-http-client';
import {
  PapiDaCommentsForumSearch,
  PapiDaCommentsForumTopics,
  PapiRequestDaCommentsForumSearch,
  PapiRequestDaCommentsForumTopics,
} from '@wix/da-papi-types';
import {
  takeStreamFetch,
  fetchStreamContents,
} from '@wix/da-shared-react/pkg/Stream/helpers';
import { updateCommentsOrderDone } from '@wix/da-shared-react/pkg/publicSession/actions';
import { addErrorNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import { getNextOffset } from '@wix/da-shared-react/pkg/Stream/withOffset/selectors';
import { navigateTo } from '../components/forums/helpers/url';
import { API_BASE_PATH } from '../../constants';
import {
  FORUM_PAGE_THREADS_STREAM,
  FORUM_SEARCH_RESULTS_STREAM,
} from '../components/forums/constants';
import { urlFromBreadcrumbs } from '../components/forums/helpers/breadcrumbs';
import {
  fetchNextThreads,
  fetchNextSearchResults,
  fetchPostInit,
  fetchPostInitSuccess,
  fetchPostInitFailure,
  postForumThread,
  postForumThreadSuccess,
  postForumThreadFailure,
  closeForumThread,
} from '../actions/forums';
import { flagEnabled } from '@wix/da-react-context/pkg/flags/redux/selectors';

const logger = ddt.createLogger('forums');
const papiProject = 'v1/dacomments';

// ---- forum thread stream
function* handleFetchNextThreads() {
  const { streamId, papiEndpointUrl } = FORUM_PAGE_THREADS_STREAM;
  const params = yield select(state => state.params || {});
  const { forumpath } = params;
  const offset = yield select(getNextOffset, streamId);

  yield fetchStreamContents<
    PapiRequestDaCommentsForumTopics,
    PapiDaCommentsForumTopics
  >({
    streamId,
    papiProject,
    url: papiEndpointUrl,
    params: { forumpath, offset, include_session: false },
    onFailure: function* (papiError) {
      logger.error(JSON.stringify(papiError));
      yield put(addErrorNotification(i18n.t('saga.fetchError.forumThreads')));
    },
    getStreamData: result => {
      const { hasMore, threads = [], nextOffset } = result;
      return {
        hasMore: !!hasMore,
        items: threads,
        nextOffset,
      };
    },
  });
}

// ---- forum search results stream
function* handleFetchNextSearchResults() {
  const { streamId, papiEndpointUrl } = FORUM_SEARCH_RESULTS_STREAM;
  const params = yield select(state => state.params || {});
  const { q, forumpath } = params;
  const offset = yield select(getNextOffset, streamId);

  yield fetchStreamContents<
    PapiRequestDaCommentsForumSearch,
    PapiDaCommentsForumSearch
  >({
    streamId,
    papiProject,
    url: papiEndpointUrl,
    params: { q, forumpath, offset, include_session: false },
    onFailure: function* (papiError) {
      logger.error(JSON.stringify(papiError));
      yield put(addErrorNotification(i18n.t('saga.fetchError.searchResults')));
    },
    getStreamData: result => {
      const { hasMore, results = [], nextOffset } = result;
      return {
        hasMore: !!hasMore,
        items: results,
        nextOffset,
      };
    },
  });
}

// ---- there's an xhr that fires to set up the thread creation modal ForumPostModal
// todo: sooooo much boilerplate for a get xhr...
function* handleFetchPostInit() {
  const result = yield call(
    requestPuppy,
    { url: '/forum/post/init' },
    handleFetchPostInitFakeResponse,
    papiProject
  );

  if (result) {
    yield put(fetchPostInitSuccess(result));
  } else {
    const { errorDescription: error = i18n.t('saga.defaultError') } = result;
    yield put(fetchPostInitFailure(error));
  }
}

export function handleFetchPostInitFakeResponse(options): any {
  if (process.env.NODE_ENV !== 'production') {
    return {
      icons: [
        'a/alien.gif',
        'a/ashamed.gif',
        'a/aww.gif',
        'b/beer.gif',
        'b/biggrin.gif',
      ],
    };
  }
}

// The thread creation post uses DaEditor data, so that needs to be handled by
// an express endpoint
function* handlePostForumThread(action: ReturnType<typeof postForumThread>) {
  const isEditorCommentFlagEnabled = yield select(
    flagEnabled,
    'editor_comment'
  );
  const options = {
    baseURL: API_BASE_PATH,
    url: '/forum/post',
    method: 'post',
    data: {
      ...action.payload,
      ...(isEditorCommentFlagEnabled ? { editor_v3: 'yes' } : {}),
    },
  } as XhrRequestOptions;
  const result = yield call(requestNapi, options, () => {});

  if (result) {
    yield put(postForumThreadSuccess(result));
    const { breadcrumbs, topicThread } = result;
    const threadUrl = urlFromBreadcrumbs(
      breadcrumbs,
      topicThread.commentableItemid
    );
    navigateTo(threadUrl);
  } else {
    const { errorDescription: error = i18n.t('saga.defaultError') } = result;
    yield put(postForumThreadFailure(error));
  }
}

function* handleUpdateCommentsOrderDone(
  action: ReturnType<typeof updateCommentsOrderDone>
) {
  window.location.reload();
}

function* handleCloseForumThread(action: ReturnType<typeof closeForumThread>) {
  const result = yield call(requestPuppy, {
    url: '/comments/closethread',
    method: 'post',
    data: { threadid: action.payload },
  });

  if (result && result.success) {
    window.location.reload();
  } else {
    logger.error(JSON.stringify(result));
    const { errorDescription: error = i18n.t('saga.defaultError') } = result;
    yield put(
      addErrorNotification(i18n.t('saga.fetchError.closeThread', { error }))
    );
  }
}

export default function* (): SagaIterator {
  yield takeStreamFetch(fetchNextThreads, handleFetchNextThreads);
  yield takeStreamFetch(fetchNextSearchResults, handleFetchNextSearchResults);
  yield takeLatest(fetchPostInit, handleFetchPostInit);
  yield takeLatest(postForumThread, handlePostForumThread);
  yield takeLatest(updateCommentsOrderDone, handleUpdateCommentsOrderDone);
  yield takeLatest(closeForumThread, handleCloseForumThread);
}
