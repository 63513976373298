import React from 'react';
import { PapiUser } from '@wix/da-papi-types';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import DisablableUsername from '../DisablableUsername';
import UserHighlight from '../UserHighlight';

import s from './UserAndTimestamp.scss';

export interface Props {
  isDisabled?: boolean;
  className?: string;
  author: PapiUser;
  timestamp?: string;
}

/** Avatar next to username on topline, timestamp on bottomline */
const UserAndTimestamp: React.FC<Props> = ({
  author,
  timestamp,
  isDisabled,
  className,
}) => {
  return (
    <UserHighlight
      className={className}
      user={author}
      isDisabled={isDisabled}
      upperLine={
        <DisablableUsername
          className={s['userlink']}
          user={author}
          isDisabled={isDisabled}
          withTooltip
        />
      }
      lowerLine={
        timestamp && (
          <Timestamp className={s['timestamp']} timestamp={timestamp} />
        )
      }
    />
  );
};

UserAndTimestamp.displayName = 'UserAndTimestamp';
export default UserAndTimestamp;
