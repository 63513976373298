import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import { AppState } from '../../../types/store';
import { appLoaded } from '../../../actions/app';

import App, { Props } from './App';

export type StateProps = Pick<Props, 'environment'>;
export type DispatchProps = Pick<Props, 'appLoaded'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    pageType: state.pageType,
    environment: getEnv(state).type,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  appLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
