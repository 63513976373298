import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import LargeInputFieldWithButton from '@wix/da-ds/pkg/formControls/dsPresets/LargeInputFieldWithButton';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import { navigateTo } from '../../helpers/url';
import { ROOT_PATH } from '../../constants';

import s from './ForumSearchBar.scss';

export interface Props {
  defaultValue?: string;
  forumpath?: string;
  placeholder?: string;
  buttonText?: string;
  className?: string;
  /** Function called when search is submitted. Passed search url based on search term.
   *  Defaults to navigating to that url */
  onSearch?: (url: string) => void;
  /** Called the x in the input is pressed or pressing enter or the search button
   *  when the input is empty */
  onClear?: () => void;
}

/** User avatar to the left and two lines of info on the right (one on top of the other) */
const ForumSearchBar: React.FC<Props> = ({
  defaultValue = '',
  forumpath,
  placeholder,
  buttonText,
  className,
  onSearch,
  onClear = noop,
}) => {
  const { t } = useTranslation();
  const isMobile: boolean = useContext(MobileContext);
  const [currentSearchTerm, setCurrentSearchTerm] = useState(defaultValue);

  const navigateToSearchPage = searchTerm => {
    searchTerm && navigateTo(getForumSearchUrl(searchTerm, forumpath));
  };

  const handleSearch = onSearch || navigateToSearchPage;
  const searchSubmit = e => {
    e.preventDefault();
    currentSearchTerm ? handleSearch(currentSearchTerm) : onClear();
  };

  const defaultPlaceholder = t(
    isMobile
      ? 'ForumSearchBar.searchPlaceholder.mobile'
      : 'ForumSearchBar.searchPlaceholder'
  );
  return (
    <form className={className} onSubmit={searchSubmit}>
      <LargeInputFieldWithButton
        name="searchTerm"
        layout="horizontal"
        prefix={<SearchIcon className={s['search-icon']} />}
        placeholder={placeholder || defaultPlaceholder}
        buttonProps={{
          children: buttonText || t('ForumSearchBar.searchButton'),
        }}
        value={currentSearchTerm}
        onChange={event => setCurrentSearchTerm(event.target.value)}
        onClear={() => {
          setCurrentSearchTerm('');
          onClear();
        }}
      />
    </form>
  );
};

ForumSearchBar.displayName = 'ForumSearchBar';
export default ForumSearchBar;

const getForumSearchUrl = (q: string, searchForumpath = ''): string => {
  // precondition: q !== ''
  const url = new URL(Url.to(`${ROOT_PATH}/search?q=${q}`));
  if (searchForumpath) {
    url.searchParams.set('forumpath', searchForumpath);
  }
  return url.toString();
};
