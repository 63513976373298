import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { requestPuppy } from '@wix/da-http-client';
import { publishCommentSuccess } from '@wix/da-shared-react/pkg/CommentEditor/redux/actionCreators';
import { publishCommentSuccess as publishCommentSuccessNew } from '@wix/da-shared-react/pkg/CommentEditor.editor_v3/redux/actionCreators';
import {
  fetchCommentHistory,
  fetchCommentHistorySuccess,
} from '../actions/comments';
import { AppState } from '../types/store';

export function fakeResponse(options): any {
  if (process.env.NODE_ENV !== 'production') {
    const Fake = require('@wix/da-sample-data').Fake;
    const fakeHistory = Fake.makeCommentHistory(7);
    return fakeHistory;
  }
}

function* handleCommentPublishSaga(
  action: ReturnType<typeof publishCommentSuccess>
) {
  const { itemId, commentId }: { itemId?: number; commentId?: number } =
    action.payload.comment || {};

  if (!itemId || !commentId) {
    return;
  }

  const [currentItemId, currentCommentId] = yield select((state: AppState) => [
    Number(state.comments?.itemId),
    Number(state.comments?.commentId),
  ]);

  if (commentId === currentCommentId && itemId === currentItemId) {
    // published comment is the root one
    yield put(
      fetchCommentHistory({
        itemId,
        commentId,
      })
    );
  }
}

function* fetchCommentHistorySaga(
  action: ReturnType<typeof fetchCommentHistory>
) {
  const { itemId, commentId } = action.payload;
  if (!itemId || !commentId) {
    return;
  }

  const result = yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/comments/history',
      params: {
        itemid: itemId,
        commentid: commentId,
      },
    },
    fakeResponse
  );

  if (result) {
    yield put(
      fetchCommentHistorySuccess({
        history: result.history,
      })
    );
  }
}

export default function* commentHistoryRootSaga(): SagaIterator {
  yield takeLatest(publishCommentSuccess, handleCommentPublishSaga);
  yield takeLatest(publishCommentSuccessNew, handleCommentPublishSaga);
  yield takeLatest(fetchCommentHistory, fetchCommentHistorySaga);
}
