import { connect, MapStateToProps } from 'react-redux';
import {
  PapiDaCommentsForumSearch,
  PapiRequestDaCommentsForumSearch,
} from '@wix/da-papi-types';
import { AppState } from '../../../types/store';

import { Props } from './ForumSearchPage';

export type StateProps = Props;
export type OwnProps = Omit<Props, keyof StateProps>;
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const { breadcrumbs, nextOffset, estTotal, errorCode } =
    state.forums as PapiDaCommentsForumSearch;
  return {
    breadcrumbs,
    nextOffset,
    estTotal,
    errorCode,
    params: state.params as PapiRequestDaCommentsForumSearch,
  };
};

export default connect(mapStateToProps);
