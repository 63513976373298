import { connect, MapStateToProps } from 'react-redux';
import { PapiDaCommentsForumInit } from '@wix/da-papi-types';
import { AppState } from '../../../types/store';

import { Props } from './ForumListPage';

export type StateProps = Props;
export type OwnProps = Omit<Props, keyof StateProps>;
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const {
    breadcrumbs,
    groups: forumCategories,
    popularThreads,
  } = state.forums as PapiDaCommentsForumInit;
  return { breadcrumbs, forumCategories, popularThreads, params: state.params };
};

export default connect(mapStateToProps);
