import React from 'react';
import cn from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';

import s from './UserHighlight.scss';

const AVATAR_SIZE = 32;

export interface Props {
  user: PapiUser;
  upperLine: React.ReactNode;
  lowerLine: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
}

/** User avatar to the left and two lines of info on the right (one on top of the other) */
const UserHighlight: React.FC<Props> = ({
  user,
  upperLine,
  lowerLine,
  isDisabled,
  className,
}) => {
  return (
    <div className={cn(className, s['root'])}>
      <UserAvatar
        className={s['avatar']}
        user={user}
        size={AVATAR_SIZE}
        {...(isDisabled
          ? { withLink: false, withTooltip: false }
          : { withTooltip: true })}
      />
      <div className={s['upper']}>{upperLine}</div>
      <div className={s['lower']}>{lowerLine}</div>
    </div>
  );
};

UserHighlight.displayName = 'UserHighlight';
export default UserHighlight;
