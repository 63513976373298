import React from 'react';
import { ForumsModalType } from '../types';
import ForumPostModal from '../_partials/ForumPostModal';

const forumModalRenderer = (
  modalType: string,
  onRequestClose: () => void,
  params?: any
) => {
  switch (modalType) {
    case ForumsModalType.FORUM_POST_MODAL:
      return <ForumPostModal onRequestClose={onRequestClose} {...params} />;
    default:
      return null;
  }
};

export default forumModalRenderer;
