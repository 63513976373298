import { PapiForumBreadcrumb } from '@wix/da-papi-types';
import { urlTo } from './url';

export function getCurrentBreadcrumb(
  breadcrumbs = [] as PapiForumBreadcrumb[]
): PapiForumBreadcrumb {
  return breadcrumbs[breadcrumbs.length - 1];
}

export function urlFromBreadcrumbs(
  breadcrumbs = [] as PapiForumBreadcrumb[],
  threadId?: number
): string | undefined {
  const { link } = getCurrentBreadcrumb(breadcrumbs) || {};
  return !link ? undefined : urlTo(threadId ? `${link}/${threadId}` : link);
}

export function getCurrentBreadcrumbName(
  breadcrumbs = [] as PapiForumBreadcrumb[]
): string | undefined {
  return (getCurrentBreadcrumb(breadcrumbs) || {}).name;
}
