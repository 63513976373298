import { StreamType } from '@wix/da-shared-react/pkg/Stream';
import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/base/types';
import { fetchNextThreads, fetchNextSearchResults } from '../../actions/forums';

export const ROOT_PATH = '/forum';
export const EMOTICONS_URL = 'https://e.deviantart.net/emoticons/';
export const EMOTICON_SIZE = 16;

export const STREAM_THROTTLE = 500;

export const FORUM_PAGE_THREADS_STREAM = {
  streamId: 'FORUM_PAGE_THREADS',
  streamConfig: {
    autoFetch: false,
    streamType: StreamType.WITH_OFFSET,
    // hardcoded on the backend
    // itemsPerFetch: 50,
    fetchNextCallback: fetchNextThreads,
  } as StreamInitConfig,
  papiEndpointUrl: 'forum/topics',
};

export const FORUM_SEARCH_RESULTS_STREAM = {
  streamId: 'FORUM_SEARCH_RESULTS',
  streamConfig: {
    autoFetch: false,
    streamType: StreamType.WITH_OFFSET,
    // hardcoded on the backend
    // itemsPerFetch: 50,
    fetchNextCallback: fetchNextSearchResults,
  } as StreamInitConfig,
  papiEndpointUrl: 'forum/search',
};

export const enum AdoptsModalType {
  SELLER_MODAL = 'ADOPT_SELLER_MODAL',
}
