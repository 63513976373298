import React, { useContext } from 'react';
import cn from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BasicUserName, {
  Props as BasicUserNameProps,
} from '@wix/da-shared-react/pkg/User/BasicUserName';
import UserLinkWithTooltip, {
  Props as UserLinkWithTooltipProps,
} from '@wix/da-shared-react/pkg/User/Link/UserLinkWithTooltip';

import s from './DisablableUsername.scss';

type UsernameProps = BasicUserNameProps & UserLinkWithTooltipProps;
export interface Props extends UsernameProps {
  isDisabled?: boolean;
}

const DisablableUsername: React.FC<Props> = ({
  isDisabled,
  className,
  ...props
}) => {
  const isMobile = useContext(MobileContext);
  const noInteraction = isDisabled || isMobile;
  return noInteraction ? (
    <span className={cn(className, s['root'])}>
      <BasicUserName usernameClassName={s['username']} {...props} />
    </span>
  ) : (
    <UserLinkWithTooltip
      className={cn(className, s['root'])}
      contentClassName={s['username']}
      {...props}
    />
  );
};
DisablableUsername.displayName = 'DisablableUsername';

export default DisablableUsername;
