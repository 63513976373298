import React from 'react';
import ddt from '@wix/da-ddt';
import { Props as CommentProps } from '@wix/da-shared-react/pkg/Comment/presets/Comment';
import ThreadHeader from '@wix/da-shared-react/pkg/CommentThread/_partials/ThreadHeader';
import ThreadPostbox from '@wix/da-shared-react/pkg/CommentThread/_partials/ThreadPostbox';
import ThreadStack from '@wix/da-shared-react/pkg/CommentThread/_partials/ThreadStack';
import {
  ThreadProps,
  ThreadOrder,
} from '@wix/da-shared-react/pkg/CommentThread/types';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator/LoadingIndicator';
import ThreadPagination from '@wix/da-shared-react/pkg/CommentThread/_partials/ThreadPagination';
import { BiData, LoadMoreCommentsClickBiEvent } from '@wix/da-bi/pkg/events';

import s from './ForumThread.scss';

const logger = ddt.createLogger('comments');

export interface Props extends ThreadProps {
  streamId: string;
  itemCount: number;
  isFetching?: boolean;

  maxDepthOverride?: number;
  extraInHeader?: React.ReactNode;
  commentComponent?: React.ComponentType<CommentProps>;
  replyIndentModifier?: number;

  className?: string;
  threadStackClassName?: string;
  commentWrapperClassName?: string;
  updateCommentsOrder: (order: ThreadOrder) => void;
}

const ForumThread: React.FC<Props> = ({
  commentableItemId,
  commentableTypeId,
  parentId,
  parentComment,
  parentCommentRef,
  className,
  maxCommentsShown,
  extraInHeader,
  allowSelfInit,
  commentComponent,
  commentWrapperClassName,
  maxDepthOverride,
  replyIndentModifier,
  threadStackClassName,
  updateCommentsOrder,
  isFetching,
  streamId,
}) => {
  // todo: remove if/when closing T62564
  logger.log('ForumThread', {
    commentableItemId,
    commentableTypeId,
    parentId,
    maxCommentsShown,
    maxDepthOverride,
    replyIndentModifier,
    isFetching,
    streamId,
  });
  return (
    <div className={className}>
      <ThreadHeader
        commentableItemId={commentableItemId}
        commentableTypeId={commentableTypeId}
        parentId={parentId}
        withOrder
        withTitle
        className={s['header']}
        // note: this updates the user's preference and (in the forums saga)
        // reloads this page when the setting is complete
        onOrderChanged={(_, order) => updateCommentsOrder(order)}
      >
        {extraInHeader}
      </ThreadHeader>

      <div className={s['scrollable']}>
        <div>
          <ThreadPostbox
            position="top"
            commentableItemId={commentableItemId}
            commentableTypeId={commentableTypeId}
            parentId={parentId}
            parentComment={parentComment}
            autoFocus={false}
          />

          <ThreadStack
            className={threadStackClassName}
            commentableItemId={commentableItemId}
            commentableTypeId={commentableTypeId}
            parentId={parentId}
            maxCommentsShown={maxCommentsShown}
            commentComponent={commentComponent}
            parentComment={parentComment}
            parentCommentRef={parentCommentRef}
            allowSelfInit={allowSelfInit}
            maxDepthOverride={maxDepthOverride}
            commentWrapperClassName={commentWrapperClassName}
            replyIndentModifier={replyIndentModifier}
            enableForumThreadRootlessThreadMode
          />
          {isFetching && <LoadingIndicator view="llama" />}
          <ThreadPagination
            direction="next"
            className={s['load-more']}
            isInfinite
            streamId={streamId}
            biData={BiData<LoadMoreCommentsClickBiEvent>({
              evid: 182,
              itemid: commentableItemId,
              typeid: commentableTypeId,
            })}
          />
        </div>
      </div>
    </div>
  );
};

ForumThread.displayName = 'ForumThread';

export default ForumThread;
