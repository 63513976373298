import { handleActions, Action } from 'redux-actions';
import {
  fetchCommentHistorySuccess,
  FetchCommentHistorySuccessPayload,
} from '../actions/comments';
import { CommentsState } from '../types/comments';

const defaultState = {};

const handleFetchCommentHistorySuccess = (
  state: CommentsState,
  action: Action<FetchCommentHistorySuccessPayload>
): CommentsState => {
  const { history } = action.payload!;
  return { ...state, parentHistory: history };
};

const reducer = handleActions(
  {
    [fetchCommentHistorySuccess.toString()]: handleFetchCommentHistorySuccess,
  },
  defaultState
);

export default reducer;
