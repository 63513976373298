export const APP_LOADED = 'APP_LOADED';
export const PATH_CHANGE = 'PATH_CHANGE';
export const APP_LOADED_SUCCESSFULLY = 'APP_LOADED_SUCCESSFULLY';
export const APP_LOADED_UNSUCCESSFULLY = 'APP_LOADED_UNSUCCESSFULLY';
import { createAction } from 'redux-actions';

export const appLoaded = () => ({
  type: APP_LOADED,
});

export const pathChange = (location: object) => ({
  type: PATH_CHANGE,
  location,
});

export const appLoadSuccess = createAction(
  APP_LOADED_SUCCESSFULLY,
  result => result
);

export const appLoadFailure = createAction(
  APP_LOADED_UNSUCCESSFULLY,
  result => result
);
