import React, { useEffect } from 'react';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import SiteHeader from '@wix/da-shared-react/pkg/SiteHeader';
import SiteFooter from '@wix/da-shared-react/pkg/SiteFooter';
import DrawerLoadable from '@wix/da-feedback-shared/pkg/Drawer/DrawerLoadable';
import FeedbackPageLoadable from '@wix/da-feedback-shared/pkg/FeedbackPage/FeedbackPageLoadable';
import { composeModalRenderers } from '@wix/da-shared-react/pkg/Modals/helpers';
import { adoptsModalRenderer } from '@wix/da-adopts-shared/pkg/project-tools';
import { submitModalRenderer } from '@wix/da-submit-shared/pkg/project-tools';
import ForumListPage from '../ForumListPage';
import ForumPage from '../ForumPage';
import ThreadPage from '../ThreadPage';
import ForumSearchPage from '../ForumSearchPage';
import forumModalRenderer from './Modals';

import s from './App.scss';

const modalsRenderFunc = composeModalRenderers(
  forumModalRenderer,
  adoptsModalRenderer,
  submitModalRenderer
);

export interface Props {
  language: string;
  environment?: RuntimeEnvironment;
  pageType?: string;
  appLoaded: () => void;
}

const App: React.FC<Props> = ({
  language,
  environment,
  pageType,
  appLoaded,
}) => {
  useEffect(() => {
    appLoaded();
  }, [appLoaded]);

  return (
    <DaApp
      language={language}
      environment={environment}
      bodyClassName={s['root']}
      modalsRenderFunc={modalsRenderFunc}
      ncContextProps={{
        PageComponent: FeedbackPageLoadable,
        DrawerComponent: DrawerLoadable,
      }}
      disableHeadTags
    >
      <div className={s['header']}>
        <SiteHeader />
      </div>
      {getForumPage(pageType)}
      <SiteFooter />
    </DaApp>
  );
};

export default App;

function getForumPage(pageType?: string): React.ReactNode {
  switch (pageType) {
    case 'forum':
      return <ForumPage />;
    case 'forumThread':
      return <ThreadPage />;
    case 'forumSearch':
      return <ForumSearchPage />;
    case 'forumList':
    default:
      return <ForumListPage />;
  }
}
