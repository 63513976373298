import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  PapiDaCommentsForumTopics,
  PapiRequestDaCommentsForumTopics,
  PapiForum,
} from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  Main,
  Section,
} from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import AddCircleIcon from '@wix/da-ds/pkg/Icons/AddCircle';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { usePushModal } from '@wix/da-shared-react/pkg/utils/hooks/usePushModal';
import { ForumsModalType } from '../types';
import { FORUM_PAGE_THREADS_STREAM, STREAM_THROTTLE } from '../constants';
import { getCurrentBreadcrumbName } from '../helpers/breadcrumbs';
import ForumSearchBar from '../_partials/ForumSearchBar';
import Breadcrumbs from '../_partials/Breadcrumbs';
import ThreadEntry from '../_partials/ThreadEntry';
import ThreadStreamPagination from '../_partials/ThreadStreamPagination';
import PopularThreads from '../_partials/PopularThreads';

import rowSpacing from './rowSpacing.scss';
import s from './ForumPage.scss';

export interface Props extends PapiDaCommentsForumTopics {
  params?: PapiRequestDaCommentsForumTopics;
}

const ForumPage: React.FC<Props> = ({
  breadcrumbs,
  stickyThreads = [],
  popularThreads,
  canPost,
  nextOffset,
  params,
}) => {
  const { forumpath } = params || {};
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;

  const forumName = getCurrentBreadcrumbName(breadcrumbs);
  const forumTitle = (
    <Heading className={s['forum-title']}>{forumName}</Heading>
  );

  const { streamId, streamConfig } = FORUM_PAGE_THREADS_STREAM;
  const { items: threads } = useStream(streamId, STREAM_THROTTLE, {
    ...streamConfig,
  });

  const openForumPostModal = usePushModal(ForumsModalType.FORUM_POST_MODAL);
  const postButton = isDesktop ? (
    <SolidButton
      className={s['post-button']}
      variant="approval-green"
      size="medium"
      onClick={() => openForumPostModal({ forumpath: params?.forumpath })}
      disabled={!canPost}
    >
      {t('ForumPostModal.open')}
    </SolidButton>
  ) : (
    // why a different button type just for mobile!?
    <TextButton
      className={s['post-button']}
      variant="main"
      size="medium"
      postfix={AddCircleIcon}
      disabled={!canPost}
      onClick={() => openForumPostModal({ forumpath: params?.forumpath })}
    >
      {t('ForumPostModal.open')}
    </TextButton>
  );

  const haveThreads = threads.length > 0 || stickyThreads.length > 0;
  return (
    <div className={s['root']}>
      <header className={s['header']}>
        <ForumSearchBar
          className={s['search']}
          forumpath={forumpath}
          placeholder={t(
            isMobile
              ? 'ForumPage.searchPlaceholder.mobile'
              : 'ForumPage.searchPlaceholder'
          )}
          buttonText={t('ForumPage.searchButton')}
        />
        <Breadcrumbs className={s['breadcrumbs']} breadcrumbs={breadcrumbs} />
        {isDesktop && postButton}
      </header>

      <Main className={s['main']}>
        {haveThreads && (
          <Section className={s['threads']}>
            {isDesktop ? (
              <div
                className={cn(
                  s['thread-list-header'],
                  rowSpacing['thread-list-row-spacing']
                )}
              >
                {forumTitle}
                <span>{t('ForumPage.listHeading.author')}</span>
                <span>{t('ForumPage.listHeading.replies')}</span>
                <span>{t('ForumPage.listHeading.latestReply')}</span>
              </div>
            ) : (
              <div className={s['thread-list-header']}>
                {forumTitle}
                {postButton}
              </div>
            )}

            {!!stickyThreads?.length && (
              <ol className={s['sticky-thread-list']}>
                {stickyThreads.map((thread: PapiForum) => {
                  return (
                    <li key={thread.threadId} className={s['thread-li']}>
                      <ThreadEntry
                        className={rowSpacing['thread-list-row-spacing']}
                        thread={thread}
                      />
                    </li>
                  );
                })}
              </ol>
            )}

            <ol>
              {threads.map((thread: PapiForum) => {
                return (
                  <li key={thread.threadId} className={s['thread-li']}>
                    <ThreadEntry
                      className={rowSpacing['thread-list-row-spacing']}
                      thread={thread}
                    />
                  </li>
                );
              })}

              <ThreadStreamPagination
                className={s['pagination']}
                streamId={streamId}
                forumpath={forumpath}
                nextOffset={nextOffset}
              />
            </ol>
          </Section>
        )}

        {!haveThreads && (
          <Section className={s['no-results']}>
            <Heading>{t('ForumPage.noResults')}</Heading>
            {postButton}
          </Section>
        )}

        {!isMobile && (
          <PopularThreads
            className={s['popular-threads']}
            popularThreads={popularThreads}
          />
        )}
      </Main>
    </div>
  );
};
ForumPage.displayName = 'ForumPage';

export default ForumPage;
