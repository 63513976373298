import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  PapiDaCommentsForumSearch,
  PapiRequestDaCommentsForumSearch,
  PapiForum,
} from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import {
  Main,
  Section,
} from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { FORUM_SEARCH_RESULTS_STREAM, STREAM_THROTTLE } from '../constants';
import { navigateTo, urlFromForumpath } from '../helpers/url';
import ThreadEntry from '../_partials/ThreadEntry';
import ForumSearchBar from '../_partials/ForumSearchBar';
import ThreadStreamPagination from '../_partials/ThreadStreamPagination';
import SearchBreadcrumbs from './SearchBreadcrumbs';

import rowSpacing from './rowSpacing.scss';
import s from './ForumSearchPage.scss';

export interface Props extends PapiDaCommentsForumSearch {
  params?: PapiRequestDaCommentsForumSearch;
}

const ForumSearchPage: React.FC<Props> = ({
  breadcrumbs,
  nextOffset,
  estTotal,
  errorCode,
  params: { q: searchTerm, forumpath } = {},
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;

  const { streamId, streamConfig } = FORUM_SEARCH_RESULTS_STREAM;
  const { items: results } = useStream(streamId, STREAM_THROTTLE, {
    ...streamConfig,
  });

  // if the user searches for nothing here, send them to the forumpath
  const onClear = () => {
    navigateTo(urlFromForumpath(forumpath));
  };

  return (
    <div className={s['root']}>
      <header className={s['header']}>
        <ForumSearchBar
          className={s['search']}
          defaultValue={searchTerm}
          forumpath={forumpath}
          onClear={onClear}
        />
        <SearchBreadcrumbs
          className={s['breadcrumbs']}
          estTotal={estTotal}
          searchTerm={searchTerm}
          breadcrumbs={breadcrumbs}
        />
      </header>

      <Main className={s['main']}>
        {results?.length ? (
          <Section className={s['results']}>
            {isDesktop && (
              <div
                className={cn(
                  s['thread-list-header'],
                  rowSpacing['search-results-row-spacing']
                )}
              >
                <span>{t('ForumSearchPage.listHeading.thread')}</span>
                <span>{t('ForumSearchPage.listHeading.forum')}</span>
                <span>{t('ForumSearchPage.listHeading.author')}</span>
                <span>{t('ForumSearchPage.listHeading.replies')}</span>
              </div>
            )}

            <ol>
              {results.map((thread: PapiForum) => {
                return (
                  <li key={thread.threadId} className={s['thread-li']}>
                    <ThreadEntry
                      className={rowSpacing['search-results-row-spacing']}
                      variant="search"
                      thread={thread}
                    />
                  </li>
                );
              })}
            </ol>

            <ThreadStreamPagination
              className={s['pagination']}
              streamId={streamId}
              forumpath={forumpath}
              nextOffset={nextOffset}
            />
          </Section>
        ) : (
          <Section className={s['no-results']}>
            <Heading>{t('ForumSearchPage.noResults')}</Heading>
          </Section>
        )}
      </Main>
    </div>
  );
};
ForumSearchPage.displayName = 'ForumSearchPage';

export default ForumSearchPage;
