import { connect, MapStateToProps } from 'react-redux';
import {
  PapiDaCommentsForumTopics,
  PapiRequestDaCommentsForumTopics,
} from '@wix/da-papi-types';
import { AppState } from '../../../types/store';

import { Props } from './ForumPage';

export type StateProps = Pick<Props, 'breadcrumbs' | 'canPost' | 'params'>;
export type OwnProps = Omit<Props, keyof StateProps>;
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const { breadcrumbs, canPost, stickyThreads, popularThreads } =
    state.forums as PapiDaCommentsForumTopics;
  return {
    breadcrumbs,
    canPost,
    stickyThreads,
    popularThreads,
    params: state.params as PapiRequestDaCommentsForumTopics,
  };
};

export default connect(mapStateToProps);
