import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForumBreadcrumb } from '@wix/da-papi-types';
import { urlTo, urlFromForumpath } from '../../helpers/url';

import s from './Breadcrumbs.scss';

export interface Props {
  className?: string;
  breadcrumbs?: PapiForumBreadcrumb[];
  /** Optional text transform function to update each link in the breadcrumbs */
  transformLinkFn?: (link: string) => string;
}

/** this > style > of > nav for forums */
const Breadcrumbs: React.FC<Props> = ({
  className,
  breadcrumbs = [],
  transformLinkFn = urlTo,
}) => {
  const { t } = useTranslation();

  if (!breadcrumbs.length) {
    return null;
  }

  const crumbsWithForumHome = [
    {
      name: t('Breadcrumbs.rootForumName'),
      link: urlFromForumpath('/'),
    },
    ...breadcrumbs,
  ];
  const lastCrumb = crumbsWithForumHome[crumbsWithForumHome.length - 1];

  return (
    <nav className={cn(s['root'], className)}>
      {crumbsWithForumHome.map(breadcrumb => {
        const { name, link } = breadcrumb;
        const isLastCrumb = breadcrumb === lastCrumb;
        const classes = cn(isLastCrumb && s['last'], s['breadcrumb']);
        return (
          <React.Fragment key={name}>
            <a className={classes} href={transformLinkFn(link)}>
              {name}
            </a>

            {!isLastCrumb && <span aria-hidden> &gt; </span>}
          </React.Fragment>
        );
      })}
    </nav>
  );
};
Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
