// DS: TA 3.4 Forum Thread Notice (aka ThreadNotice)
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import LockIcon from '@wix/da-ds/pkg/Icons/24x24/Lock';
import DisabledCommentIcon from '@wix/da-ds/pkg/Icons/DisabledComment';

import s from './ThreadNotice.scss';

const VARIANT_CONTENTS = {
  closed: {
    icon: LockIcon,
    textLocaleKey: 'ThreadNotice.closed',
  },
  auto_closed: {
    icon: LockIcon,
    textLocaleKey: 'ThreadNotice.auto_closed',
  },
  admin: {
    icon: DisabledCommentIcon,
    textLocaleKey: 'ThreadNotice.admin',
  },
};

export interface Props {
  noticeType: 'closed' | 'auto_closed';
  isAdmin?: boolean;
  className?: string;
}

/** quasi-notification strip with an icon and text, so far only used
 *  to indicate a locked thread */
const ThreadNotice: React.FC<Props> = ({ noticeType, isAdmin, className }) => {
  const { t } = useTranslation();
  const variant = isAdmin ? 'admin' : noticeType;
  const { icon: Icon, textLocaleKey } = VARIANT_CONTENTS[variant] || {};
  if (!Icon || !textLocaleKey) {
    return null;
  }

  return (
    <aside className={cn(className, s['root'])}>
      <Icon />
      <span>{t(textLocaleKey)}</span>
    </aside>
  );
};

ThreadNotice.displayName = 'ThreadNotice';
export default ThreadNotice;
