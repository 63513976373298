import { all, fork } from 'redux-saga/effects';
import { sagas } from '@wix/da-shared-react/pkg/redux/shared';
import { sisuFunnelSaga } from '@wix/da-shared-react/pkg/redux/sisuFunnel/saga';
import forumsSaga from './forums';
import commentsSaga from './comments';
import appSaga from './app';

export default function* daCommentsRootSaga() {
  yield all([
    fork(forumsSaga),
    fork(commentsSaga),
    fork(appSaga),
    fork(sisuFunnelSaga),
    ...sagas.map(fork),
  ]);
}
