import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import CommentEditor from '@wix/da-editor-tiptap/pkg/components/Editor/presets/Comment';
import { externalRenderers } from '@wix/da-shared-react/pkg/DaEditorNg/config';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import useEditorNg from '@wix/da-shared-react/pkg/DaEditorNg/hooks/useEditorNg';
import EditorCommon from '@wix/da-shared-react/pkg/DaEditorNg/EditorCommon';

export interface Props {
  className?: string;
}

const ForumPostEditor: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    editorProps: { className: editorClassName, ...restEditorProps },
    editorSharedProps,
    editorCommonProps,
  } = useEditorNg({
    autoFocus: false,
    // onEditorReady: tools => setToolbars(tools),
    // onEditorChange: setContentEditorState,
  });

  return (
    <Suspense>
      <EditorCommon {...editorCommonProps} />
      <CommentEditor
        {...editorSharedProps}
        {...restEditorProps}
        className={c(editorClassName, className)}
        placeholder={t('ForumPostModal.body.placeholder')}
        externalRenderers={externalRenderers}
        cookieType={MeasuredCookieType.COMMENT_RENDERER}
      />
    </Suspense>
  );
};
ForumPostEditor.displayName = 'ForumPostEditor';

export default ForumPostEditor;
