import { Url } from '@wix/da-url';
import { ROOT_PATH } from '../constants';

/** Makes a local dev link relative by clipping the hostname */
export function urlTo(url: string): string {
  const fixedForEnvironment = Url.to(url);
  return fixedForEnvironment.replace(/^https:\/\/www\.deviantart\.lan/, '');
}

/** Creates a forum url given a forumpath */
export function urlFromForumpath(forumpath = '/'): string {
  const host = Url.to(ROOT_PATH);
  return forumpath !== '/' ? `${host}/${decodeURIComponent(forumpath)}` : host;
}

/** Non-destructively updates url to include the k/v as a querystring param */
export function setParam(url: string, key: string, value: any): string {
  const returned = new URL(url);
  returned.searchParams.set(key, value);
  return returned.toString();
}

/** Navigates the window (if any) to the given url */
export function navigateTo(url?: string): void {
  url && typeof window !== 'undefined' && window.location.assign(urlTo(url));
}
