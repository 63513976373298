import React from 'react';
import cn from 'classnames';
import { PapiForum } from '@wix/da-papi-types';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import { urlFromBreadcrumbs } from '../../helpers/breadcrumbs';
import UserHighlight from '../UserHighlight';
import DisablableUsername from '../DisablableUsername';
import DisablableButtonOrLink from '../DisablableButtonOrLink';

import s from './ThreadSummary.scss';

interface ThreadSummaryProps {
  thread: PapiForum;
  isDisabled?: boolean;
  className?: string;
}
const ThreadSummary: React.FC<ThreadSummaryProps> = ({
  thread,
  isDisabled,
  className,
}) => {
  const { threadId, breadcrumbs, author, subject, timestamp } = thread;
  const threadUrl = urlFromBreadcrumbs(breadcrumbs, threadId);

  return (
    <UserHighlight
      className={cn(className, s['root'])}
      isDisabled={isDisabled}
      user={author}
      upperLine={
        <DisablableButtonOrLink
          className={s['subject']}
          href={threadUrl}
          disabled={isDisabled}
        >
          {subject}
        </DisablableButtonOrLink>
      }
      lowerLine={
        <>
          <DisablableUsername
            className={s['userlink']}
            user={author}
            isDisabled={isDisabled}
            withTooltip
          />
          <Timestamp timestamp={timestamp} showTooltip />
        </>
      }
    />
  );
};
ThreadSummary.displayName = 'ThreadSummary';

export default ThreadSummary;
