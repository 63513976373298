// DS: TA 3.3 Forum Entry Popular (aka PopularThread)
import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForum } from '@wix/da-papi-types';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import CommentIcon from '@wix/da-ds/pkg/Icons/16x16/Comment';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { urlFromBreadcrumbs } from '../../helpers/breadcrumbs';
import { urlTo } from '../../helpers/url';
import { ROOT_PATH } from '../../constants';
import UserHighlight from '../UserHighlight';
import DisablableUsername from '../DisablableUsername';
import DisablableButtonOrLink from '../DisablableButtonOrLink';

import s from './PopularThread.scss';

export interface Props {
  thread: PapiForum;
  isDisabled?: boolean;
  hideForumLink?: boolean;
  className?: string;
}

/** User avatar to the left and two lines of info on the right (one on top of the other) */
const PopularThread: React.FC<Props> = ({
  thread,
  isDisabled,
  hideForumLink,
  className,
}) => {
  const {
    breadcrumbs,
    author,
    threadId,
    forumTitle,
    forumPath,
    subject,
    replyCount,
  } = thread;
  const { t } = useTranslation();
  const threadUrl = urlTo(`${ROOT_PATH}/${forumPath}/${threadId}`);
  const forumUrl = urlFromBreadcrumbs(breadcrumbs);
  return (
    <Section
      className={cn(className, isDisabled && s['root-disabled'], s['root'])}
    >
      <UserHighlight
        isDisabled={isDisabled}
        user={author}
        upperLine={
          <DisablableButtonOrLink
            className={s['subject']}
            href={threadUrl}
            disabled={isDisabled}
          >
            <Heading>{subject}</Heading>
          </DisablableButtonOrLink>
        }
        lowerLine={
          <div className={s['byline-and-forum']}>
            <span>
              {t('PopularThread.byline', {
                postProcess: 'reactPostprocessor',
                username: (
                  <DisablableUsername
                    user={author}
                    isDisabled={isDisabled}
                    withTooltip
                  />
                ),
              })}
            </span>

            {!hideForumLink && <span className={s['divider']} />}

            {!hideForumLink && (
              <DisablableButtonOrLink
                className={s['forum']}
                href={forumUrl}
                disabled={isDisabled}
              >
                {forumTitle}
              </DisablableButtonOrLink>
            )}
          </div>
        }
      />

      <DisablableButtonOrLink
        className={s['replies']}
        href={threadUrl}
        disabled={isDisabled}
      >
        <CommentIcon className={s['replies-icon']} size={IconSize.SMALLER} />{' '}
        {replyCount}
        <VisuallyHidden>{t('PopularThread.a11y.replies')}</VisuallyHidden>
      </DisablableButtonOrLink>
    </Section>
  );
};

PopularThread.displayName = 'PopularThread';
export default PopularThread;
