// DS: TA 3.1a Forum Entry (for lists of forums)
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PapiForumSection } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { urlTo } from '../../helpers/url';
import DisablableButtonOrLink from '../DisablableButtonOrLink';
import ThreadSummary from './ThreadSummary';

import s from './ForumEntry.scss';

export interface Props {
  forum: PapiForumSection;
  className?: string;
}

const ForumEntry: React.FC<Props> = ({ forum, className }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const {
    breadcrumb,
    countTopics,
    countPosts,
    description,
    latestMessage,
    peekOnly,
  } = forum;
  const { link, name } = breadcrumb;

  // todo: how does peekOnly here interact/affect peekOnly at the forumCategory level?
  // peekOnly && console.log(name, { peekOnly });

  // don't show latestMessage if peekOnly (that'd be peeking?)
  const showLatestMessage = latestMessage && !peekOnly;

  const countTopicsString = countTopics.toLocaleString();
  const countTopicsWithLabel = t('ForumEntry.threadsLabelAndCount', {
    countTopics: countTopicsString,
  });
  const countPostsString = countPosts.toLocaleString();
  const countPostsWithLabel = t('ForumEntry.postsLabelAndCount', {
    countPosts: countPostsString,
  });
  return (
    <div className={cn(className, peekOnly && s['root-disabled'], s['root'])}>
      <DisablableButtonOrLink
        className={s['forum-link']}
        disabled={peekOnly}
        href={urlTo(link)}
      >
        <span className={s['name-and-desc']}>
          <span className={s['name']}>{name}</span>
          <span>{description}</span>
        </span>

        {/* only visually show the Topics, Posts labels on mobile */}
        {isMobile ? (
          <span>{countTopicsWithLabel}</span>
        ) : (
          <span>
            <span aria-hidden={true}>{countTopicsString}</span>
            <VisuallyHidden>{countTopicsWithLabel}</VisuallyHidden>
          </span>
        )}

        {isMobile ? (
          <span>{countPostsWithLabel}</span>
        ) : (
          <span>
            <span aria-hidden={true}>{countPostsString}</span>
            <VisuallyHidden>{countPostsWithLabel}</VisuallyHidden>
          </span>
        )}
      </DisablableButtonOrLink>

      {!isMobile &&
        (showLatestMessage ? (
          <ThreadSummary
            className={s['latest-message']}
            thread={latestMessage}
          />
        ) : (
          <span className={s['latest-message']} />
        ))}
    </div>
  );
};
ForumEntry.displayName = 'ForumEntry';

export default ForumEntry;
