import { SagaIterator } from 'redux-saga';
import { takeEvery, put, all } from 'redux-saga/effects';
import {
  logPageView,
  biModalUnload,
} from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { exitDuperbrowseDone } from '@wix/da-shared-react/pkg/Duperbrowse/redux/actionCreators';

function* pageViewHandler(action) {
  yield put(
    logPageView({
      view: 'other',
      component: 'comments',
    })
  );
}

export default function* appSaga(): SagaIterator {
  yield all([
    takeEvery(
      [appMounted, exitDuperbrowseDone, biModalUnload],
      pageViewHandler
    ),
  ]);
}
