// DS: TA 3.6 Submit Forum Emote Chooser (aka IconSelector)
import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { yieldToMain } from '@wix/da-hooks/pkg/yieldToMain';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { EMOTICONS_URL } from '../../constants';

import s from './IconSelector.scss';

export interface Props {
  icons: string[];
  selectedIcon: string;
  isDisabled?: boolean;
  className?: string;
  onChange?: (newIcon: string) => void;
}

const IconSelector: React.FC<Props> = ({
  icons = [],
  selectedIcon,
  isDisabled,
  className,
  onChange = noop,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => yieldToMain(() => setIsOpen(v => !v));

  // TODO: there's no great way to capture the esc key inside a modal here
  // in order to close this on the first esc, THEN the modal on the second press
  // people are going to lose their content because of this
  return (
    <div className={cn(className, s['root'])}>
      <PlainButtonOrLink
        onClick={toggleOpen}
        className={s['opener']}
        disabled={isDisabled}
      >
        <VisuallyHidden>{t('IconSelector.a11y.buttonLabel')}</VisuallyHidden>
        <img
          className={s['selected-icon']}
          src={`${EMOTICONS_URL}/${selectedIcon}`}
          // speak the name, so the user knows what icon they'll be posting
          alt={iconFilenameOnly(selectedIcon)}
        />
      </PlainButtonOrLink>

      {isOpen && (
        <ul
          className={s['selections']}
          role="radiogroup"
          aria-labelledby="selections-label"
          aria-hidden={!isOpen}
        >
          <VisuallyHidden id="selections-label">
            {t('IconSelector.a11y.selectionsLabel')}
          </VisuallyHidden>

          {icons.length ? (
            icons.map(iconChoice => {
              const isSelected = iconChoice === selectedIcon;
              const ariaName = iconFilenameOnly(iconChoice);
              return (
                <li key={iconChoice}>
                  <PlainButtonOrLink
                    className={cn(
                      isSelected && s['selected-choice'],
                      s['icon-choice']
                    )}
                    role="radio"
                    aria-checked={isSelected}
                    aria-label={ariaName}
                    onClick={() => {
                      toggleOpen();
                      onChange(iconChoice);
                    }}
                  >
                    <img
                      src={`${EMOTICONS_URL}/${iconChoice}`}
                      alt={ariaName}
                    />
                  </PlainButtonOrLink>
                </li>
              );
            })
          ) : (
            <LoadingIndicator className={s['loading-indicator']} />
          )}
        </ul>
      )}
    </div>
  );
};

IconSelector.displayName = 'IconSelector';
export default IconSelector;

const iconFilenameOnlyRegex = /^\w+\/([\w.]+)$/;
/** converts 'b/beer.gif' to 'beer.gif' */
function iconFilenameOnly(iconPath: string): string {
  return iconPath.replace(iconFilenameOnlyRegex, '$1');
}
