import { connect, MapStateToProps } from 'react-redux';
import {
  PapiDaCommentsForumTopicInit,
  PapiRequestDaCommentsForumTopicInit,
} from '@wix/da-papi-types';
import { AppState } from '../../../types/store';

import { Props } from './ThreadPage';

export type StateProps = Props;
export type OwnProps = Omit<Props, keyof StateProps>;
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const { breadcrumbs, topicTitle, topicComment, topicThread, topicClosed } =
    state.forums as PapiDaCommentsForumTopicInit;
  return {
    breadcrumbs,
    topicTitle,
    topicComment,
    topicThread,
    topicClosed,
    params: state.params as PapiRequestDaCommentsForumTopicInit,
  };
};

export default connect(mapStateToProps);
