import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiForumGroup } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import CoreSymbol from '@wix/da-ds/pkg/Indicators/CoreSymbol';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import ForumEntry from '../_partials/ForumEntry';

import s from './ForumCategory.scss';

interface Props {
  category: PapiForumGroup;
  className?: string;
  rowSpacingClass?: string;
}

const ForumCategory: React.FC<Props> = ({
  // ?todo: how does peekOnly here supposed to affect the individual forums? pass it onto them?
  category: { title, subsections = [], icon: iconProp, peekOnly },
  className,
  rowSpacingClass,
}) => {
  const { t } = useTranslation();
  const isDesktop = !useContext(MobileContext);
  if (!subsections.length) {
    return null;
  }

  const Icon = getIcon(iconProp);
  return (
    <Section className={cn(className, s['root'])}>
      <div className={cn(s['header'], rowSpacingClass)}>
        <div className={s['header-title-and-stats']}>
          {isDesktop && Icon ? (
            <Heading className={cn(s['title'])}>
              {Icon} {title}
            </Heading>
          ) : (
            <Heading className={cn(s['title'])}>{title}</Heading>
          )}
          <span>{t('ForumListPage.ForumCategory.threadsLabel')}</span>
          <span>{t('ForumListPage.ForumCategory.postsLabel')}</span>
        </div>

        <span>{t('ForumListPage.ForumCategory.latestThreadLabel')}</span>
      </div>

      <ol>
        {subsections?.map(subsection => {
          return (
            <li key={subsection.breadcrumb.name} className={s['forum-entry']}>
              <ForumEntry className={rowSpacingClass} forum={subsection} />
            </li>
          );
        })}
      </ol>
    </Section>
  );
};
ForumCategory.displayName = 'ForumCategory';

export default ForumCategory;

function getIcon(icon?: string): React.ReactNode {
  switch (icon) {
    case 'core':
      return <CoreSymbol size={IconSize.SMALL} />;
    default:
  }
  return null;
}
