import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import { fetchPostInit, postForumThread } from '../../../../actions/forums';
import { PapiDaCommentsForumPostInitXHR } from '../../../../types/forums';

import { Props } from './ForumPostModal';

type StateProps = Pick<Props, 'postInitFetchStatus' | 'icons'>;
type DispatchProps = Pick<Props, 'fetchPostInit' | 'postForumThread'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;
const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const { postInitFetchStatus, icons } =
    state.forums as PapiDaCommentsForumPostInitXHR;
  return { postInitFetchStatus, icons };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  fetchPostInit,
  postForumThread,
};

export default connect(mapStateToProps, mapDispatchToProps);
