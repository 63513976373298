import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Props } from './ForumThread';
import { getThreadState } from '@wix/da-shared-react/pkg/CommentThread/selectors';
import { updateCommentsOrder } from '@wix/da-shared-react/pkg/publicSession/actions';

export type StateProps = Pick<Props, 'streamId' | 'itemCount' | 'isFetching'>;
export type DispatchProps = Pick<Props, 'updateCommentsOrder'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, {}> = (
  state,
  { commentableTypeId, commentableItemId, parentId }
) => {
  const threadState = getThreadState(
    state,
    commentableTypeId,
    commentableItemId,
    parentId
  );
  return {
    ...threadState,
    itemCount: threadState.items.length,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  updateCommentsOrder,
};

export default connect(mapStateToProps, mapDispatchToProps);
