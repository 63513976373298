/* eslint-disable @wix/da/import-scss-last */
import 'react-hot-loader/patch';
import React from 'react';
import Modal from 'react-modal';
import { hydrateRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import axios from 'axios';
import { wixAxiosConfig } from '@wix/wix-axios-config';
import { getClientInstance } from '@wix/da-ddt';
import initI18n from '@wix/da-i18next/pkg/presetI18nClientConfig';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import { XHR_BASE_PATH } from '../constants';
import { configureStore } from './store/configure-store';
// project-base needs to go before app styles
import '@wix/da-ds/src/styles/project-base.scss';
import App from './components/forums/App';
import AppDocumentWrapper from '@wix/da-react-app/pkg/client/AppDocumentWrapper';

const ddt = getClientInstance();
ddt.replayServerSideLogs(window['__DDT__']);

const initialState = window['__INITIAL_STATE__'];
delete window['__INITIAL_STATE__'];
const store = configureStore(initialState);
const state = store.getState();

const initialI18n = window['__INITIAL_I18N__'];
initI18n(
  {
    ...initialI18n,
    options: {
      defaultNS: 'forums',
      fallbackNS: 'translation',
    },
  },
  getEnv(state).isDebug,
  getEnv(state).flags
);

wixAxiosConfig(axios, { baseURL: XHR_BASE_PATH });
Modal.setAppElement('body');

const root = hydrateRoot(
  document,
  <AppDocumentWrapper store={store}>
    <App language={initialI18n.locale} />
  </AppDocumentWrapper>
);

if (module.hot) {
  module.hot.accept('./components/comments/App', () => {
    // eslint-disable-next-line global-require
    const AppLatest = require('./components/comments/App').default;
    root.render(
      <AppContainer>
        <AppDocumentWrapper store={store}>
          <AppLatest language={initialI18n.locale} />
        </AppDocumentWrapper>
      </AppContainer>
    );
  });
}
