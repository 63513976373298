// DS: TA 3.5 Submit Forum Thread (aka ForumPostModal)
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Modal, { ModalProps } from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import InputLabelText from '@wix/da-ds/pkg/formControls/lego/InputLabelText';
import InputErrorMessage from '@wix/da-ds/pkg/formControls/lego/InputErrorMessage';
import FormElement from '@wix/da-ds/pkg/formControls/layouts/FormElement';
import { yieldToMain } from '@wix/da-hooks/pkg/yieldToMain';
import {
  EditorContext,
  EditorContextProvider,
} from '@wix/da-editor-tiptap/pkg/components/Editor/Context';
import ForumPostEditor from './ForumPostEditor';
import ForumPostEditorToolbar from './ForumPostEditorToolbar';
import {
  PapiDaCommentsForumPostInitXHR,
  PapiRequestDaCommentsForumPostXHR,
} from '../../../../types/forums';
import IconSelector from './IconSelector';

import s from './ForumPostModal.scss';

const DEFAULT_ICON = 'a/alien.gif';

type ModalAndXhrProps = Pick<ModalProps, 'onRequestClose'> &
  PapiDaCommentsForumPostInitXHR;
export interface Props extends ModalAndXhrProps {
  forumpath: string;
  fetchPostInit: () => void;
  postForumThread: (postData: PapiRequestDaCommentsForumPostXHR) => void;
}

const Content: React.FC<Props> = ({
  icons = [],
  postInitFetchStatus: { isFetching, error } = {},
  forumpath,
  fetchPostInit,
  postForumThread,
  onRequestClose,
  ...props
}) => {
  const {
    isReady: isEditorReady,
    isEmpty: isEditorEmpty,
    getStateForSave: getEditorStateForSave,
  } = useContext(EditorContext);
  const { t } = useTranslation();
  const [icon, setIcon] = useState<string>(DEFAULT_ICON);
  const [subject, setSubject] = useState<string>('');
  const canSubmit = icon && subject && !isEditorEmpty;
  const isMobile = useContext(MobileContext);
  /** don't show required errors at first */
  const [isDisplayingValidationErrors, setIsDisplayingValidationErrors] =
    useState(false);

  const onSubmit = () => {
    yieldToMain(() => {
      if (!isEditorReady) {
        return;
      }

      if (!canSubmit) {
        setIsDisplayingValidationErrors(true);
        return;
      }
      postForumThread({
        forumpath,
        icon,
        subject,
        editorRaw: JSON.stringify(getEditorStateForSave()),
      });
    });
  };

  useEffect(() => {
    fetchPostInit();
  }, [fetchPostInit]);

  return (
    <Modal
      // FEATURE CLEANUP: editor_comment merge root-new classname
      className={c(s['root'], s['root-new'])}
      contentClassName={s['content']}
      useFixedMobileHeight={isMobile}
      isFullscreen={isMobile}
      isOpen
      onRequestClose={onRequestClose}
      showHeaderBorder
      heading={t('ForumPostModal.heading')}
      showFooterBorder
      footerRight={[
        <TextButton
          key="cancel"
          variant="main"
          size="large"
          onClick={onRequestClose}
        >
          {t('ForumPostModal.cancel')}
        </TextButton>,

        <BrandedButton
          key="submit"
          variant="brand-green"
          size="large"
          onClick={onSubmit}
        >
          {t('forums:ForumPostModal.submit')}
        </BrandedButton>,
      ]}
    >
      {error && <div className={s['error']}>{t('ForumPostModal.error')}</div>}

      <IconSelector
        className={s['icon-selector']}
        icons={icons}
        selectedIcon={icon}
        onChange={setIcon}
      />

      <TextInput
        wrapperClassName={s['subject-input']}
        name="subject"
        id="subject"
        label={t('ForumPostModal.subject.label')}
        placeholder={t('ForumPostModal.subject.placeholder')}
        autoFocus={true}
        maxLength={100}
        value={subject}
        onChange={e => setSubject(e.target.value)}
        {...(isDisplayingValidationErrors &&
          !subject && {
            validationResult: 'error',
            details: t('ForumPostModal.subject.requiredError'),
          })}
        isRequired
      />

      <FormElement
        className={s['editor-and-toolbar']}
        inputId="forum-post-content"
        {...(isDisplayingValidationErrors &&
          isEditorEmpty && {
            validationResult: 'error',
            details: (
              <InputErrorMessage>
                {t('ForumPostModal.body.requiredError')}
              </InputErrorMessage>
            ),
          })}
        label={
          <InputLabelText className={s['editor-label']} isRequired>
            {t('ForumPostModal.body.label')}
          </InputLabelText>
        }
      >
        <ForumPostEditor className={s['editor']} />
        <ForumPostEditorToolbar className={s['editor-toolbar']} />
      </FormElement>
    </Modal>
  );
};

const ForumPostModal: React.FC<Props> = props => {
  return (
    <EditorContextProvider>
      <Content {...props} />
    </EditorContextProvider>
  );
};
ForumPostModal.displayName = 'ForumPostModal';

export default ForumPostModal;
