import { Reducer } from 'redux';
import { reducers as sharedReducers } from '@wix/da-shared-react/pkg/redux/shared';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';
import { AppState } from '../types/store';
import commentsReducer from './comments';
import forumsReducer from './forums';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, action) =>
    state;

// This type makes sure we do not forget to add a reducer for any slice
// of the store nor can we add extras for non-existing slices.
// Needed because monday (thursday) mornings exist.

type AppStateKeys = keyof AppState;
type AppStateReducersMapObject = { [k in AppStateKeys]: Reducer<any> };

const rootReducer: AppStateReducersMapObject = {
  // TODO?: combine into something like pageData
  pageType: buildFakeReducer(''),
  isEmbed: buildFakeReducer(false),
  params: buildFakeReducer({}),
  // note: shared/Comment/redux/saga selects from here
  comments: commentsReducer,
  forums: forumsReducer,
  ...sharedReducers,
  ...headReducers,
};

export default rootReducer;
