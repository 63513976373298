import { createAction } from 'redux-actions';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';
import {
  PapiDaCommentsForumPost,
  PapiDaCommentsForumPostInit,
  PapiRequestDaCommentsForumPost,
} from '@wix/da-papi-types';

export const fetchNextThreads = createCallbackActionCreator(
  'FORUMS.FETCH_THREADS'
);

export const fetchNextSearchResults = createCallbackActionCreator(
  'FORUMS.FETCH_SEARCH_RESULTS'
);

// sooooo much boilerplate for a get xhr...
export const fetchPostInit = createAction('FORUMS.FETCH_POST_INIT', () => {});
export const fetchPostInitSuccess = createAction(
  'FORUMS.FETCH_POST_INIT.SUCCESS',
  (payload: PapiDaCommentsForumPostInit) => payload
);
export const fetchPostInitFailure = createAction(
  'FORUMS.FETCH_POST_INIT.FAILURE',
  (error: any) => error
);

export const postForumThread = createAction(
  'FORUMS.POST_FORUM_THREAD',
  (payload: PapiRequestDaCommentsForumPost) => payload
);
export const postForumThreadSuccess = createAction(
  'FORUMS.POST_FORUM_THREAD.SUCCESS',
  (payload: PapiDaCommentsForumPost) => payload
);
export const postForumThreadFailure = createAction(
  'FORUMS.POST_FORUM_THREAD.FAILURE',
  (error: any) => error
);

export const closeForumThread = createAction(
  'FORUMS.CLOSE_FORUM_THREAD',
  (threadid: number) => threadid
);
