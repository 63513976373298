import { handleActions } from 'redux-actions';
import { PapiDaCommentsForumPostInit } from '@wix/da-papi-types';
import {
  fetchPostInit,
  fetchPostInitSuccess,
  fetchPostInitFailure,
} from '../actions/forums';
import { ForumAppState } from '../types/forums';

const defaultState = {};

const reducer = handleActions(
  {
    [fetchPostInit.toString()]: (state: ForumAppState): ForumAppState => {
      return {
        ...state,
        postInitFetchStatus: { isFetching: true, error: undefined },
      };
    },
    [fetchPostInitSuccess.toString()]: (
      state: ForumAppState,
      action: { payload: PapiDaCommentsForumPostInit }
    ): ForumAppState => {
      const { icons } = action.payload;
      return {
        ...state,
        icons,
        postInitFetchStatus: { isFetching: false, error: undefined },
      };
    },
    [fetchPostInitFailure.toString()]: (
      state: ForumAppState,
      action: { payload: any }
    ): ForumAppState => {
      const { error } = action.payload;
      return {
        ...state,
        postInitFetchStatus: { isFetching: false, error },
      };
    },
  },
  defaultState
);

export default reducer;
