import React, { Suspense, useContext } from 'react';
import c from 'classnames';
import AddDeviationToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/Deviation';
import GalleryToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/Gallery';
import VideoToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/Video';
import ReactionToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/Reaction';
import HRuleToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/HRule';
import GifToobarButton from '@wix/da-editor-tiptap/pkg/components/menus/buttons/Gif';
import { EditorContext } from '@wix/da-editor-tiptap/pkg/components/Editor/Context';
import s from './ForumPostEditorToolbar.scss';

export interface Props {
  className?: string;
}

const ForumPostEditorToolbar: React.FC<Props> = ({ className }) => {
  const { ref: editor } = useContext(EditorContext);

  return (
    editor && (
      // Suspense here for the dynamically loaded translation namespace these buttons require.
      <Suspense>
        <div className={c(s['root'], className)}>
          <AddDeviationToobarButton editor={editor} />
          <GalleryToobarButton editor={editor} />
          <VideoToobarButton editor={editor} />
          <ReactionToobarButton editor={editor} />
          <HRuleToobarButton editor={editor} />
          <GifToobarButton editor={editor} />
        </div>
      </Suspense>
    )
  );
};
ForumPostEditorToolbar.displayName = 'ForumPostEditorToolbar';

export default ForumPostEditorToolbar;
