import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PapiDaCommentsForumInit,
  PapiRequestDaCommentsForumInit,
} from '@wix/da-papi-types';
import { Main } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { getCurrentBreadcrumbName } from '../helpers/breadcrumbs';
import Breadcrumbs from '../_partials/Breadcrumbs';
import ForumSearchBar from '../_partials/ForumSearchBar';
import PopularThreads from '../_partials/PopularThreads';
import ForumCategory from './ForumCategory';

import rowSpacing from './rowSpacing.scss';
import s from './ForumListPage.scss';

export interface Props
  extends Omit<PapiDaCommentsForumInit, 'session' | 'groups'> {
  forumCategories: PapiDaCommentsForumInit['groups'];
  params?: PapiRequestDaCommentsForumInit;
}

/** Main list or subcategory list of DA forums */
const ForumListPage: React.FC<Props> = ({
  breadcrumbs,
  forumCategories,
  popularThreads,
  params,
}) => {
  const { forumpath } = params || {};
  const { t } = useTranslation();

  const breadcrumbName = getCurrentBreadcrumbName(breadcrumbs);
  const title = breadcrumbName || t('ForumListPage.rootForumTitle');

  return (
    <div className={s['root']}>
      <header className={s['header']}>
        <Heading className={s['title']}>{title}</Heading>
        <Breadcrumbs className={s['breadcrumbs']} breadcrumbs={breadcrumbs} />
        <ForumSearchBar className={s['search']} forumpath={forumpath} />
      </header>

      <Main className={s['main']}>
        <ol className={s['forum-list']}>
          {!!forumCategories &&
            forumCategories.map((category, i) => {
              return (
                <li key={category.title} className={s['category-li']}>
                  <ForumCategory
                    rowSpacingClass={rowSpacing['forum-list-row-spacing']}
                    category={category}
                  />
                </li>
              );
            })}
        </ol>

        <PopularThreads
          className={s['popular-threads']}
          popularThreads={popularThreads}
        />
      </Main>
    </div>
  );
};
ForumListPage.displayName = 'ForumListPage';

export default ForumListPage;
