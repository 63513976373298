import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ddt from '@wix/da-ddt';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import {
  default as OnScrollBottom,
  DISTANCE_UNIT,
} from '@wix/da-shared-react/pkg/OnScrollBottom';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { yieldToMain } from '@wix/da-hooks/pkg/yieldToMain';
import { setParam, urlFromForumpath } from '../../helpers/url';

import s from './ThreadStreamPagination.scss';

const logger = ddt.createLogger('forums');

// we need to account for the footer since we're using the page height
const DESKTOP_DIST_TO_PAGE_END =
  400 /* ~footer */ + 60 /* bottom padding */ + 100; /* distance to list end */
const MOBILE_DIST_TO_PAGE_END = 590 + 24 + 100;

export interface Props {
  streamId: string;
  forumpath?: string;
  nextOffset?: number;
  className?: string;
}

/** SEO-friendly button and loading indicator to load second page of threads
 *  and use infinite scrolling after */
const ThreadStreamPagination: React.FC<Props> = ({
  streamId,
  forumpath,
  nextOffset,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { hasMore, isFetching, fetchNext } = useStream(streamId);

  // only (pagintate once on desktop)
  const [usingInfiniteScroll, setUsingInfiniteScroll] = useState(false);
  logger.log('ThreadStreamPagination', {
    hasMore,
    isFetching,
    usingInfiniteScroll,
  });

  const hrefToNextPage = setParam(
    urlFromForumpath(forumpath),
    'offset',
    nextOffset
  );
  const distanceToLoadMore = isMobile
    ? MOBILE_DIST_TO_PAGE_END
    : DESKTOP_DIST_TO_PAGE_END;
  return hasMore ? (
    <div className={cn(className, s['root'])}>
      {isFetching && <LoadingIndicator className={s['loading-indicator']} />}

      {!isFetching && !usingInfiniteScroll && (
        <TextButton
          className={s['load-more-button']}
          variant="main"
          size="large"
          href={hasMore ? hrefToNextPage : undefined}
          onClick={event => {
            event.preventDefault();
            yieldToMain(() => {
              setUsingInfiniteScroll(true);
              fetchNext();
            });
          }}
        >
          {t('ThreadStreamPagination.loadMore')}
        </TextButton>
      )}

      {!isFetching && usingInfiniteScroll && (
        <OnScrollBottom
          checkOnMount
          checkOnUpdate={hasMore}
          onScrolledToBottom={() => fetchNext()}
          distanceToBottom={distanceToLoadMore}
          distanceUnit={DISTANCE_UNIT.PX}
        />
      )}
    </div>
  ) : null;
};
ThreadStreamPagination.displayName = 'ThreadStreamPagination';

export default ThreadStreamPagination;
